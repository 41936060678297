<template>
  <div>
    <div class="blog-page-left">
      <b-row>
        <template v-for="(item, key) in posts">
          <b-col md="12" lg="6" :key="key">
            <div class="single-blog">
              <div class="blog-img">
                <b-link>
                  <img :src="item.img" alt="blog" />
                </b-link>
              </div>
              <div class="blog-text">
                <div class="blog-meta">
                  <p><i class="fa fa-user"></i> {{item.postBy}}</p>
                  <p><i class="fa fa-clock-o"></i> {{item.date}}</p>
                </div>
                <h3><b-link>{{item.title}}</b-link></h3>
                <p>{{item.description}}</p>
              </div>
              <div class="blog-btn">
                <b-link><i class="fa fa-arrow-right"></i></b-link>
              </div>
            </div>
          </b-col>
        </template>
      </b-row>
    </div>
    <!--Blog Pagination Area-->
    <blog-pagination/>
  </div>
</template>

<script>
import BlogPagination from "./BlogPagination";
export default {
  name: "BlogPost",
  components: {BlogPagination},
  data() {
    return {
      posts: [
        {
          img: require('../../assets/img/blog-1.jpeg'),
          postBy: 'By Jessica Brown',
          date: '05 Nov, 2021',
          title: 'Using Hashtag Effectively In Social Media',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          img: require('../../assets/img/blog-2.jpeg'),
          postBy: 'By Smith Sharlin',
          date: '14 Nov, 2021',
          title: 'Microsoft confirms it is merging with idea cellular.',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          img: require('../../assets/img/blog-3.jpeg'),
          postBy: 'By Jonathon Doe',
          date: '14 Nov, 2021',
          title: 'Digital Marketing Trends That Drive Sales',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          img: require('../../assets/img/blog-1.jpeg'),
          postBy: 'By Jessica Brown',
          date: '11 Nov, 2021',
          title: 'Using Hashtag Effectively In Social Media',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          img: require('../../assets/img/blog-2.jpeg'),
          postBy: 'By Smith Sharlin',
          date: '14 Nov, 2021',
          title: 'Microsoft confirms it is merging with idea cellular.',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          img: require('../../assets/img/blog-3.jpeg'),
          postBy: 'By Jonathon Doe',
          date: '14 Nov, 2021',
          title: 'Digital Marketing Trends That Drive Sales',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>