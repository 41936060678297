/*=========================================================================================
File Name: Blog.vue
Description: Blog Page.
----------------------------------------------------------------------------------------
Item Name: Ekifa Vue || Business and Agency VueJs Template
Version: 1.0.0
Author: Deveable
Copyright 2021
==========================================================================================*/
<template>
  <div>
    <!-- Breadcrumb Component -->
    <breadcrumb-area :breadcrumb="breadcrumb"/>
    <!-- Blog Area Component -->
    <blog-area/>
  </div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import BlogArea from "../../components/blog/BlogArea";
export default {
  name: "blog",
  components: {BlogArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: "Blog",
        pageTitle: "Blog"
      }
    }
  }
}
</script>

<style scoped>

</style>