<template>
  <div class="blog-sidebar-widget">
    <h3>Instagram</h3>
    <ul class="instagram">
      <li v-for="(item, key) in instagrams" :key="key">
        <b-link>
          <img :src="item.img" alt="instagram" />
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BlogInstagram",
  data() {
    return {
      instagrams: [
        {
          img: require('../../assets/img/img-1.jpg')
        },
        {
          img: require('../../assets/img/img-2.jpg')
        },
        {
          img: require('../../assets/img/img-3.jpg')
        },
        {
          img: require('../../assets/img/img-1.jpg')
        },
        {
          img: require('../../assets/img/img-5.jpg')
        },
        {
          img: require('../../assets/img/img-2.jpg')
        },
        {
          img: require('../../assets/img/img-3.jpg')
        },
        {
          img: require('../../assets/img/img-5.jpg')
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>