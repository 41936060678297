<template>
  <div class="blog-sidebar-widget">
    <div class="blog-social-follow">
      <b-link class="facebook-bg"><i class="fa fa-facebook"></i> 2.1k Fans</b-link>
      <b-link class="twitter-bg"><i class="fa fa-twitter"></i> 811 Followers</b-link>
      <b-link class="pinterest-bg"><i class="fa fa-pinterest"></i> 1.5k Fans</b-link>
      <b-link class="instagram-bg"><i class="fa fa-instagram"></i> 5.2k Followers</b-link>
      <b-link class="vk-bg"><i class="fa fa-vk"></i> 940k Followers</b-link>
      <b-link class="youtube-bg"><i class="fa fa-youtube"></i> 2.2k Subscriber</b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogSocial"
}
</script>

<style scoped>

</style>