<template>
  <div class="blog-sidebar-widget">
    <b-form>
      <b-form-group id="input-group-1" label-for="input-1">
        <b-form-input
            id="input-1"
            v-model="search"
            placeholder="Search..."
            required
        ></b-form-input>
      </b-form-group>
      <b-button type="submit"><i class="fa fa-search"></i></b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  name: "BlogSearch",
  data() {
    return {
      search:''
    }
  }
}
</script>

<style scoped>

</style>